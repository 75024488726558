import React from 'react';
import styled from 'styled-components';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';
import { Container } from '../../layout/Container';
import { useFormTransition } from '../../../utils/hooks/useFormTransition';
import { createLeadMutation } from '../../../../client/__graphql__/mutations';
import { useMutation } from '@apollo/client';
import { ILeadInput, ILeadResponse } from '../../../__types__/global';
import { useForm } from '../../../utils/hooks/useForm';
import { Form, FormRow, FormWrapper } from '../../ui/form/Form';
import { FormError } from '../../ui/form/FormError';
import { Input } from '../../ui/form/Input';
import { Textarea } from '../../ui/form/Textarea';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Button } from '../../ui/button/Button';
import { ButtonLink } from '../../ui/link/ButtonLink';
import { routeConfig } from '../../../__config__/routes';

const Maripaaveien: React.FC = () => {
  const [posted, setPosted] = React.useState(false);

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  const MAIL_MUTATION = createLeadMutation({
    mail: {
      response: `{
              success
            }`
    }
  });

  const [mail, { loading, error }] = useMutation<ILeadResponse, ILeadInput>(
    MAIL_MUTATION,
    {
      onCompleted: ({ lead }) => {
        if (lead.mail?.success) {
          setPosted(true);
        }
      }
    }
  );

  return (
    <MaripaaveienStyle>
      <Container>
        <Heading tag="h1" center={true}>
          Marikåpeveien 4-8
        </Heading>

        <Paragraph center={true}>
          Blir et leilighetsbygg med fantastiske bokvaliteter. Leiligheter med
          størrelser fra 88 til 128 m<sup>2</sup>-(bra), stor og solrike
          ute-plasser med utsikt, smarte planløsninger og egen
          parkeringskjeller.
        </Paragraph>

        <img
          className="maripaaveien"
          src="https://cdn.reeltime.no/pm_assets/kampanjer/maripaaveien/maripaaveien.jpg"
          alt=""
        />

        <Paragraph center={true}>
          Megler: Lars Erik Greivstad
          <div className="contact">
            <div className="tel">
              {' '}
              Telefon <a href="tel:99156031">99 15 60 31</a>{' '}
            </div>
            <div className="email">
              {' '}
              E-post
              <a href="mailto:larserik.greivstad@privatmegleren.no">
                {' '}
                larserik.greivstad@privatmegleren.no
              </a>
            </div>
          </div>
        </Paragraph>
        <FormWrapper style={fadeOut}>
          <ButtonGroup style={{ justifyContent: 'center', paddingTop: '1em' }}>
            <ButtonWrapper>
              <Button
                className="meldinteresse"
                type="primary"
                as="a"
                href="https://privatmegleren.no/meldinteresse/131229005"
                colorTheme="gold"
                disabled={loading}
                loading={loading}
              >
                Meld interesse
              </Button>
            </ButtonWrapper>
          </ButtonGroup>
        </FormWrapper>
      </Container>
    </MaripaaveienStyle>
  );
};

const MaripaaveienStyle = styled.div`
  padding-top: 7em;
  .maripaaveien {
    object-fit: contain;
    width: 100%;
    padding: 2em 0 2em;
  }

  @media all and(min-width:600px) {
    .maripaaveien {
      object-fit: contain;
      width: 100%;
      padding: 3em;
    }
  }
`;

const ButtonWrapper = styled.div`
  .meldinteresse {
    text-align: center;
  }
  @media all and(min-width:600px) {
    .meldinteresse {
      text-align: center;
    }
  }
`;

export default Maripaaveien;
